<template>
    <div style="height:300px; width:350">
      <l-map ref="map" v-model:zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="[39.47227080110711, -0.37181560089200005]">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-marker :lat-lng="[39.47227080110711, -0.37181560089200005]">
          <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
      </l-marker>
      </l-map> 
    </div>
  </template>
  
  <script>
  import "leaflet/dist/leaflet.css";
  import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
  
  export default {
    components: {
      LMap,
      LTileLayer,
    },
    data() {
      return {
        zoom: 13,
        iconWidth: 25,
        iconHeight: 40,
        maxZoom: 25,
        minZoom: 5,
      };
    },
    computed: {
      iconUrl() {
        return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
      },
      iconSize() {
        return [this.iconWidth, this.iconHeight];
      },
    },
  };

  //https://github.com/vue-leaflet/vue-leaflet
  </script>